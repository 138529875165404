/**
 * Created by joerg on 7/11/17.
 */

import {Component} from '@angular/core';

@Component({
    selector: 'four-zero-four',
    template: '404'
})
export class MnRoute404 {
    constructor() {}
}

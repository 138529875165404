"use strict";

// angular2
import {Injectable, Injector, Inject, forwardRef, NgModuleFactoryLoader} from '@angular/core';

@Injectable()
export class MnModuleService {

    mCache = {};
    mModuleLoader:any;

    constructor( @Inject(forwardRef(() => Injector)) public mInjector:Injector) {
        this.mModuleLoader = this.mInjector.get(NgModuleFactoryLoader);
        console.log("Injector",this.mInjector);
        console.log("ModuleLoader",this.mModuleLoader);
    }

    /*load(module_name) {
     if (this.mCache.hasOwnProperty(module_name)) {
     return module_name;
     }
     var me = this;
     return this.mModuleLoader.load(module_name)
     .then( function (lazy_module_factory) {
     console.log(lazy_module_factory);
     console.log(lazy_module_factory.moduleType.directives());

     var lazy_module_injector = lazy_module_factory.create(me.mInjector);
     me.mCache[module_name] = {
     factory: lazy_module_factory,
     injector: lazy_module_injector
     };
     return module_name;
     })
     }*/

    load(module_name) {
        var me = this;
        return new Promise(
            function(resolve, reject) {
                console.log(module_name);
                if (me.mCache.hasOwnProperty(module_name)) {
                    resolve(me.mCache[module_name]);
                } else {
                    me.mModuleLoader.load(module_name)
                        .then( function (lazy_module_factory) {
                            console.log(lazy_module_factory);
                            console.log(lazy_module_factory.moduleType.directives());

                            var lazy_module_injector = lazy_module_factory.create(me.mInjector);
                            me.mCache[module_name] = {
                                factory: lazy_module_factory,
                                injector: lazy_module_injector
                            };
                            resolve(me.mCache[module_name]);
                        })
                }
            }
        );

    }


    module(module_name) {
        return this.mCache[module_name];
    }

    component(module_name, component_name) {
        if (this.mCache.hasOwnProperty(module_name)) {
            var module_factory = this.mCache[module_name].factory;
            var module_directives = module_factory.moduleType.directives();
            for (let i = 0, l = module_directives.length; i < l; i++) {
                var module_directive = module_directives[i];
                if (module_directive.name === component_name) {
                    return module_directive;
                }
            }
        }
        return null;
    }
}



/**
 * Created by Joerg on 24.07.2017.
 */


import {Component,Input} from '@angular/core';

@Component({
    selector: 'mn-test-goto',
    template: `

    <button uiSref="test.config">Test Button uiSref</button>
    <button mn-goto="test.config">Test Button nm-goto</button>
    <a uiSref="test.config">Test Anchor uiSref</a>
    <a mn-goto="test.config">Test Anchor nm-goto</a>
    <button mn-goto="mnam">Test Button nm-goto MNAM</button>
    <a mn-goto="mnam">Test Anchor nm-goto MNAM</a>
    <div>{{testParamInput}}</div>
    <div>{{testDataInput}}</div>
`
})
export class MnTestGoTo {
    @Input() testParamInput:any = "";
    @Input() testDataInput:any = "";
    constructor() {}
}

/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';

//uirouter
import { trace, Category, UIRouterModule, Ng2StateDeclaration, UIRouter, Transition } from '@uirouter/angular';
import { Visualizer } from '@uirouter/visualizer';

//mn.core
import {MnBrandView,MnBrandService,MnLazy} from '@mn/core'

import {MnRoute404} from './MnRoute404'
import {MnTestGoTo} from './MnTestGoTo'
import {MnModuleService} from './MnModuleService'
import {MnGoTo, MnGoToAnchor} from './MnGoTo'

// Enables tracing (check the console) of:
// - TRANSITION transition start, redirect, success, error, ignored
// - VIEWCONFIG ui-view component creation/destruction and viewconfig de/activation
//trace.enable(Category.TRANSITION, Category.VIEWCONFIG);

let router_config_functions = [];

@NgModule({
    imports: [
        UIRouterModule.forChild({})
    ],
    exports: [
        MnGoTo, MnGoToAnchor
    ],
    declarations: [
        MnRoute404, MnTestGoTo, MnGoTo, MnGoToAnchor
    ],
    entryComponents: [
        MnTestGoTo
    ]
})
export class MnRouteModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnRouteModule,
            providers: [MnModuleService]
        };
    }
    static addConfig( router_config_function: (router: UIRouter) => void) {
        router_config_functions.push(router_config_function);
    }
}

export function routerConfigFn(router: UIRouter) {
    for (var i = 0, l = router_config_functions.length; i < l; i++) {
        router_config_functions[i](router);
    }
    let brand = MnBrandService.raw();
    if (brand.mode == 'dev') {
        router.trace.enable(Category.TRANSITION, Category.VIEWCONFIG, Category.UIVIEW, Category.RESOLVE, Category.HOOK);
        if (brand.data && brand.data.route && brand.data.route.visualizer === false) {
            // nothing
        } else {
            router.plugin(Visualizer);
        }
    } else {
        if (brand.data && brand.data.route && brand.data.route.visualizer === true) {
            router.plugin(Visualizer);
        }
    }
}

let comp_count:number = 0;

function brand_states():Ng2StateDeclaration[] {
    let brand = MnBrandService.raw();
    let result:Ng2StateDeclaration[] = [];

    console.log(brand.states);

    for (var state_name in brand.states) {
        if(!brand.states.hasOwnProperty(state_name)) continue;
        var state = brand.states[state_name];
        state['name'] = state_name;

        if (!state.resolve) {
            state.resolve = [];
        }

        for (var rr = 0, ll = state.resolve.length; rr < ll; rr++) {
            var resolvable:any = state.resolve[rr];
            if (resolvable.urlparam) {
                resolvable.token = resolvable.urlparam;
                resolvable.deps = [Transition];
                resolvable.resolveFn = (function (up) { return function (trans) { return trans.params()[up]; }}(resolvable.urlparam)) ;
            }
        }

        if (state.component) {
            var b = 'comp'+ comp_count;
            var c = state.component;
            state.component = MnLazy;
            state.resolve.push({
                token: b,
                resolveFn: (function (cc) { return function () { return cc; }}(c))
            });
            if (!state.bindings) {
                state.bindings = {};
            }
            state.bindings.comp = b;
            comp_count++;
        } else if (state.views) {
            for (var view_name in state.views) {
                if (!state.views.hasOwnProperty(view_name)) continue;
                var b = 'comp'+ comp_count;
                var c = state.views[view_name];
                console.log("UIRouter ",c);
                var bindings:any = c.bindings || {};
                bindings.comp = b;
                state.views[view_name] = {
                    component:  MnLazy,
                    bindings: bindings
                };
                state.resolve.push({
                    token: b,
                    resolveFn: (function (cc) { return function () { return cc; }}(c))
                });

                if (c.inputs) {
                    var i = 'inputs'+ comp_count;
                    state.views[view_name].bindings.inputs = i;
                    state.resolve.push({
                        token: i,
                        deps: [Transition],
                        resolveFn: (function (inputs) { return function (trans:Transition) {
                            console.log("UIRouter ",trans.params());
                            console.log("UIRouter ",trans.to().data);
                            var params = trans.params();
                            var to = trans.to();
                            var result = {};
                            for (var key in inputs) {
                                if (!inputs.hasOwnProperty(key)) continue;
                                var input = inputs[key];
                                if (input.param && params[input.param]) {
                                    result[key] = params[input.param];
                                }
                                if (input.data && to.data && to.data[input.data]) {
                                    result[key] = to.data[input.data];
                                }
                                if (input.static) {
                                    result[key] = input.static;
                                }
                            }
                            return result;
                        }}(c.inputs))
                    });
                }

                comp_count++;
            }
        }
        result.push(state);
    }

    console.log("UIRouter State",result);
    return result;
}

function router_config() {

    let brand = MnBrandService.raw();
    let hash = true;
    if (brand.data && brand.data.route && (brand.data.route['hash'] !== undefined)) {
        hash = brand.data.route['hash'];
    }
    let otherwise = '404';
    if (brand.data && brand.data.route && (brand.data.route['404'] !== undefined)) {
        otherwise = brand.data.route['404'];
    }
    return {
        states: brand_states(),
        otherwise: { state: otherwise },
        useHash: hash,
        config: routerConfigFn,
    }
}

export class mn_route {
    static configure():any {

        /*let states: Ng2StateDeclaration[] = [{
            name: "config",
            component: MnBrandView,
            url: "/config"
        },{
            name: "404",
            component: MnRoute404,
            url: "/404"
        }];*/

        return [
            MnRouteModule.forRoot(),
            UIRouterModule.forRoot(router_config())
        ];
    }
}
/**
 * Created by Joerg on 24.07.2017.
 */


// angular
import {Directive,ElementRef,Renderer2,Optional,Inject,Input,Injector} from '@angular/core';

// uirouter
import { UIRouter,AnchorUISref,UIView,ParentUIViewInject,UISref,TransitionOptions } from '@uirouter/angular';

// mncore
import {MnRenderer,MnBrandService,MnObservableResolver} from "@mn/core";


@Directive({ selector: 'a[mn-goto]' })
export class MnGoToAnchor extends MnRenderer{

    mAnchorUISref:AnchorUISref;

    constructor(public _el: ElementRef, public _renderer: Renderer2, inj:Injector) {
        super(inj);
        this.mAnchorUISref = new AnchorUISref(this._el,this._renderer);
    }

    update(anc:any) {
        console.log(anc);
        if (anc.href) this.updateAttribute('href',anc.href);
        this.updateAttribute('title', anc.title);
        this.updateAttribute('target', anc.target);
    }
}


@Directive({
    selector: '[mn-goto]',
    host: { '(click)': 'go()' }
})
export class MnGoTo {

    private mAnchorResolver:MnObservableResolver = new MnObservableResolver((anchor,value)=>{this.onAnchorChange(anchor,value)});

    mUISref:UISref;
    mAnc:any = {};
    @Input('mn-goto') set goto(value:string) {
        this.mAnchorResolver.resolve(this.Brand.anchor(value),value);

        /*this.mAnc = this.Brand.anchor(value);
        if (this.goto_anchor) {
            this.goto_anchor.update(this.mAnc);
        }
        if (this.mAnc.href && this.mAnc.href.startsWith('http')) {
            this.mUISref.state = "";
        } else {
            this.mUISref.state = value;
        }*/
    }
    @Input('mn-goto-params') set goto_params(value:any) { this.mUISref.params = value; }
    @Input('mn-goto-options') set goto_options(value:TransitionOptions) { this.mUISref.options = value; }

    constructor(public router: UIRouter, @Optional() public goto_anchor: MnGoToAnchor, @Inject(UIView.PARENT_INJECT) public parent: ParentUIViewInject, private Brand: MnBrandService) {
        this.mUISref = new UISref(this.router,this.goto_anchor === null ? null : this.goto_anchor.mAnchorUISref, this.parent);
    }

    private onAnchorChange(anc,value) {
        //this.mAnc = this.Brand.anchor(value);
        this.mAnc = anc;
        if (this.goto_anchor) {
            this.goto_anchor.update(this.mAnc);
        }
        if (this.mAnc.href && this.mAnc.href.startsWith('http')) {
            this.mUISref.state = "";
        } else {
            if (value.endsWith && value.endsWith('?')) {
                this.mUISref.state = anc;
            } else {
                this.mUISref.state = value;
            }
        }
    }

    ngOnInit() {
        this.mUISref.ngOnInit();
        if (this.goto_anchor) {
            this.goto_anchor.update(this.mAnc);
        }
    }

    ngOnDestroy() {
        this.mUISref.ngOnDestroy();
    }

    go() {
        if (this.mAnc.href && this.mAnc.href.startsWith('http')) {
            if (!this.goto_anchor) {
                window.open(this.mAnc.href, this.mAnc.target ? this.mAnc.target : undefined);
            }
        } else {
            this.mUISref.go(undefined,undefined,undefined);
        }
     }

}


